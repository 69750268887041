angular.module('fingerink').config(function config($stateProvider) {
    $stateProvider.state('main.buy', {
        url: '/buy',
        views: {
            "main": {
                controller: 'BuyCtrl',
                templateUrl: 'web/main/buy/buy.tpl.html',
                controllerAs: 'controller'
            }
        },
        data: {
            pageTitle: 'Buy'
        },
        resolve: {
                productos: productService=> productService.getProducts(),
                prices:  productService=> productService.getProductsPrices(),
                countries: fingerinkService =>fingerinkService.getCountries()
        }
    });
}).controller('BuyCtrl', function ($scope, session, productos,prices,countries, buyPlanModal) {

    var that = this;
    that.country = countries.data.find((element)=>element.id == session.get().user.company.country.id );
    that.coin = that.country.coin;
    that.products = productos.data;
    that.prices = prices.data[that.coin];
    that.buy = product => buyPlanModal.openModal(product.id);
});
